

























import Vue from 'vue';
import Component from 'vue-class-component';
import { Props, User, Global } from '@/store';
import { Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class InstallationGuides extends Vue {
    @Props.State('list') projectProperties;
    @User.State('project') project;
    @Global.Mutation('setPageTitle') setPageTitle;

    projectGuidesProp = {};
    isLoading = false;

    mounted() {
        this.setPageTitle('Installation Guide');
        this.generatePageData();
    }

    @Watch('projectProperties')
    generatePageData(){
        this.isLoading = true;
        this.projectGuidesProp = this.projectProperties.find((el) => el.name === 'meta.project_guides').value;
        this.isLoading = false;
    }
}
